import Axios from 'axios';
import { buildRequest } from './common';
export function killSessionAPI(uuid) {
    return buildRequest(() => Axios.delete(`/_polo_/api/session/${uuid}`));
}
export function retrieveSessionAPI(uuid) {
    return buildRequest(() => Axios.get(`/_polo_/api/session/${uuid}`));
}
export function trackSessionAPI(uuid) {
    return buildRequest(() => Axios.post(`/_polo_/api/session/${uuid}/track`));
}
export function untrackSessionAPI() {
    return buildRequest(() => Axios.delete(`/_polo_/api/session/<none>/track`));
}
export function retrieveSessionStatusAPI(uuid) {
    return buildRequest(() => Axios.get(`/_polo_/api/session/${uuid}/status`));
}
export function retrieveLogsAndStatusAPI(uuid, lastUUID = "<none>") {
    return buildRequest(() => Axios.get(`/_polo_/api/session/${uuid}/logs/${lastUUID}`));
}
