import { AppModel, initialAppState } from '../../state/models/app-model';
import { types } from 'mobx-state-tree';
import { isDev } from '../../utils/env';
import makeInspectable from 'mobx-devtools-mst';
export const RootStore = types.model({
    app: AppModel
});
export const store = RootStore.create({
    app: initialAppState
});
export const createStore = (state) => RootStore.create(state);
if (isDev()) {
    // onPatch(store.app, console.log);
    makeInspectable(store);
    window.store = store;
}
export * from './app-model';
export * from './application-model';
export * from './session-model';
