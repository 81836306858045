import { createNewSessionAPI } from "../../api/applications";
import { flow, types } from "mobx-state-tree";
import { SessionModel } from "./session-model";
import { ApplicationNotificationModel } from "./application-notification-model";
const checkoutObject = {
    name: types.string,
    hash: types.string,
    author: types.string,
    authorEmail: types.string,
    date: types.string,
    message: types.string,
};
export const ApplicationBranchModel = types.model({
    ...checkoutObject
});
export const ApplicationTagModel = types.model({
    ...checkoutObject
});
export const ApplicationConfigurationModel = types.model({
    id: types.string,
    name: types.string,
    hash: types.string,
    remote: types.string,
    target: types.string,
    host: types.string,
    maxConcurrentSessions: types.number,
});
export const ApplicationModel = types.model({
    filename: types.string,
    configuration: ApplicationConfigurationModel,
    folder: types.string,
    branchesMap: types.map(ApplicationBranchModel),
    tagsMap: types.map(ApplicationTagModel),
    failedSessions: types.map(SessionModel),
    notifications: types.map(ApplicationNotificationModel),
})
    .actions(self => {
    const newSession = flow(function* newSession(checkout) {
        const session = yield createNewSessionAPI(self.configuration.name, checkout);
        return session;
    });
    return { newSession };
});
export const castAPIApplicationToApplicationModel = (apiApplication) => {
    const { notifications, ...rest } = apiApplication;
    const application = rest;
    if (notifications && notifications.length) {
        application.notifications = notifications.reduce((acc, notification) => {
            acc[notification.uuid] = notification;
            return acc;
        }, {});
    }
    return application;
};
