import Axios from 'axios';
import { buildRequest } from './common';
export function retrieveStatusDataAPI() {
    return buildRequest(() => Axios.get(`/_polo_/api/status`));
}
export function createNewSessionAPI(applicationName, checkout) {
    return buildRequest(() => Axios.post(`/_polo_/api/session`, {
        checkout,
        applicationName
    }));
}
export function retrieveFailedSessionAPI(uuid) {
    return buildRequest(() => Axios.get(`/_polo_/api/failed/${uuid}`));
}
export function markFailedSessionAsAcknowledgedAPI(uuid) {
    return buildRequest(() => Axios.post(`/_polo_/api/failed/${uuid}/ack`));
}
export function retrieveFailedSessionLogsAPI(uuid) {
    return buildRequest(() => Axios.get(`/_polo_/api/failed/${uuid}/logs`));
}
