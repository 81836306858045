export var SessionStatus;
(function (SessionStatus) {
    SessionStatus["NONE"] = "";
    SessionStatus["STOPPED"] = "stopped";
    SessionStatus["STARTING"] = "starting";
    SessionStatus["STARTED"] = "started";
    SessionStatus["START_FAILED"] = "start_failed";
    SessionStatus["STOP_FAILED"] = "stop_failed";
    SessionStatus["STOPPING"] = "stopping";
    SessionStatus["DEGRADED"] = "degraded";
})(SessionStatus || (SessionStatus = {}));
export var SessionKillReason;
(function (SessionKillReason) {
    SessionKillReason["NONE"] = "";
    SessionKillReason["STOPPED"] = "stopped";
    SessionKillReason["BUILD_FAILED"] = "build_failed";
    SessionKillReason["HEALTHCHECK_FAILED"] = "healthcheck_failed";
    SessionKillReason["REPLACED"] = "replaced";
})(SessionKillReason || (SessionKillReason = {}));
