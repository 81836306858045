import { APIRequestResult } from '@polo/common/api/common';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CommitMessage } from '../shared/commit-message';
import { SessionLogs } from './session-logs';
export const FailingSession = observer((props) => {
    const [overlayBottom, setOverlayBottom] = useState(100);
    const [session, setSession] = useState(null);
    const [logs, setLogs] = useState([]);
    const history = useHistory();
    const setOverlayProportions = (proportions) => {
        const percentage = parseInt(`${proportions * 100}`);
        const inversePercentage = 100 - percentage;
        setOverlayBottom(inversePercentage);
    };
    useEffect(() => {
        Promise.all([
            props.app.failures.retrieveFailedSession(props.uuid),
            props.app.failures.retrieveFailedSessionLogs(props.uuid)
        ])
            .then(([sessionResponse, logsResponse]) => {
            if (sessionResponse.result === APIRequestResult.FAILED) {
                return history.push(`/_polo_`);
            }
            setSession(sessionResponse.payload);
            if (logsResponse.result === APIRequestResult.SUCCEEDED)
                setLogs(logsResponse.payload);
        });
    }, [props.uuid]);
    if (!session)
        return null;
    return React.createElement("div", { className: "\n        mx-auto w-full max-w-6xl flex flex-col min-w-0 min-h-0 flex-1 pt-3 font-quicksand", style: { height: 'calc(100vh - 120px)' } },
        React.createElement("div", { className: "main-gradient-faded absolute left-0 right-0 top-0 pointer-events-none", style: { bottom: `${overlayBottom}%`, zIndex: 1 } }),
        React.createElement("h1", { className: "text-4xl px-2 lg:px-0 mb-3 font-quicksand font-light text-nord1 dark:text-nord5 z-10" }, "Failing session"),
        React.createElement("div", { className: "text-lg text-nord1 dark:text-nord5 mb-4 z-10 border-l pl-3 border-gray-500" },
            React.createElement("span", null, session.displayName)),
        React.createElement(CommitMessage, { ...session, maxHeight: true }),
        React.createElement(SessionLogs, { failed: true, logs: logs, onLogsProportionChanged: setOverlayProportions }));
});
export default FailingSession;
