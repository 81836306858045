import { types } from "mobx-state-tree";
export const ModalModel = types.model({
    visible: types.optional(types.boolean, false),
    name: types.optional(types.string, ''),
}).actions(self => {
    const setVisible = (visible, name) => {
        self.visible = visible;
        self.name = name ?? '';
    };
    return { setVisible };
});
export const initialModalState = ModalModel.create({});
