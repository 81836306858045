import React from 'react';
import './button.scss';
import classnames from 'classnames';
export const Button = ({ ghost = false, success = false, danger = false, largeIcon = false, small = false, outlined = false, hidden = false, bgVisible = false, label, hideOnMobile = false, absolute = false, onClick, className = '', children, icon = null, }) => {
    const classes = classnames('button-component', {
        '--ghost': ghost,
        '--success': success,
        '--danger': danger,
        '--large-icon': largeIcon,
        '--small': small,
        '--hide-on-mobile': hideOnMobile,
        '--outlined': outlined,
        '--hidden': hidden,
        '--bg-visible': bgVisible,
        '--absolute': absolute,
    }, className);
    return React.createElement("a", { className: classes, onClick: onClick },
        label && React.createElement("span", null, label),
        children,
        icon);
};
