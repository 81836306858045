import { APIRequestResult } from "../../api/common";
import { killSessionAPI, retrieveLogsAndStatusAPI, retrieveSessionStatusAPI, trackSessionAPI, untrackSessionAPI } from "../../api/session";
import { flow, types } from "mobx-state-tree";
import { SessionStatus, SessionKillReason } from "./session-model-enums";
export const SessionConfigurationModel = types.model({
    isDefault: types.boolean,
});
export var SessionLogType;
(function (SessionLogType) {
    SessionLogType["TRACE"] = "trace";
    SessionLogType["DEBUG"] = "debug";
    SessionLogType["INFO"] = "info";
    SessionLogType["WARN"] = "warn";
    SessionLogType["ERROR"] = "error";
    SessionLogType["CRITICAL"] = "critical";
    SessionLogType["STDOUT"] = "stdout";
    SessionLogType["STDERR"] = "stderr";
    SessionLogType["STDIN"] = "stdin";
})(SessionLogType || (SessionLogType = {}));
export const SessionLogModel = types.model({
    when: types.string,
    uuid: types.string,
    type: types.enumeration(Object.values(SessionLogType)),
    message: types.string,
});
export const castAPISessionToSessionModel = (apiSession) => {
    const { logs, ...rest } = apiSession;
    const session = rest;
    if (logs) {
        session.logs = logs.reduce((acc, log) => {
            acc[log.uuid] = log;
            return acc;
        }, {});
    }
    else {
        session.logs = {};
    }
    return session;
};
export const SessionModel = types.model({
    uuid: types.string,
    displayName: types.string,
    alias: types.string,
    target: types.string,
    port: types.number,
    applicationName: types.string,
    status: types.enumeration(Object.values(SessionStatus)),
    createdAt: types.string,
    commitID: types.string,
    commitMessage: types.string,
    commitAuthorName: types.string,
    commitAuthorEmail: types.string,
    commitDate: types.string,
    logs: types.map(SessionLogModel),
    checkout: types.string,
    age: types.number,
    folder: types.string,
    replacesSessions: types.array(types.string),
    beingReplacedBy: types.maybe(types.late(() => SessionModel)),
    configuration: SessionConfigurationModel,
    killReason: types.enumeration(Object.values(SessionKillReason)),
    replacedBy: types.string,
    permalink: types.string,
    smartURL: types.string,
}).views(self => ({
    get beingReplacedBySession() {
        return self.beingReplacedBy;
    }
})).actions(self => {
    const track = flow(function* track() {
        const trackRequest = yield trackSessionAPI(self.uuid);
        return trackRequest;
    });
    const untrack = flow(function* untrack() {
        const untrack = yield untrackSessionAPI();
        return untrack;
    });
    const retrieveAge = flow(function* retrieveAge() {
        const age = yield retrieveSessionStatusAPI(self.uuid);
        if (age.result === APIRequestResult.SUCCEEDED) {
            self.age = age.payload;
        }
        return age;
    });
    const retrieveLogsAndStatus = flow(function* retrieveLogsAndStatus(lastLogUUID) {
        const logsAndStatus = yield retrieveLogsAndStatusAPI(self.uuid, lastLogUUID);
        if (logsAndStatus.result === APIRequestResult.SUCCEEDED) {
            self.status = logsAndStatus.payload.status;
            for (const log of logsAndStatus.payload.logs) {
                self.logs.set(log.uuid, log);
            }
        }
        return logsAndStatus;
    });
    const kill = flow(function* kill() {
        const kill = yield killSessionAPI(self.uuid);
        return kill;
    });
    return { retrieveAge, track, untrack, kill, retrieveLogsAndStatus };
});
