export var APIRequestResult;
(function (APIRequestResult) {
    APIRequestResult["FAILED"] = "failed";
    APIRequestResult["SUCCEEDED"] = "succeeded";
})(APIRequestResult || (APIRequestResult = {}));
export var APIRequestFailReason;
(function (APIRequestFailReason) {
    APIRequestFailReason["UNKNOWN"] = "unknown-error";
    APIRequestFailReason["NOT_FOUND"] = "not-found";
    APIRequestFailReason["SERVER_ERROR"] = "server-error";
})(APIRequestFailReason || (APIRequestFailReason = {}));
export function isAxiosError(e) {
    return 'isAxiosError' in e;
}
export async function buildRequest(request) {
    try {
        const response = await request();
        return {
            result: APIRequestResult.SUCCEEDED,
            payload: response.data.result
        };
    }
    catch (e) {
        let reason = APIRequestFailReason.UNKNOWN;
        if (isAxiosError(e)) {
            if (e.response?.status == 404) {
                reason = APIRequestFailReason.NOT_FOUND;
            }
            else {
                reason = e.response?.data.reason;
            }
        }
        return {
            result: APIRequestResult.FAILED,
            reason
        };
    }
}
