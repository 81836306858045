import { observer } from 'mobx-react-lite';
import React from 'react';
import { useParams } from 'react-router-dom';
import FailingSession from './failing-session';
export const FailingSessionPage = observer((props) => {
    const params = useParams();
    const uuid = params.uuid;
    return React.createElement(FailingSession, { app: props.app, uuid: uuid });
});
export default FailingSessionPage;
