import React from 'react';
import './notification.scss';
import classnames from 'classnames';
import { XIcon } from '@heroicons/react/outline';
import { NotificationType } from '@polo/common/state/models/notification-model';
export const Notification = ({ notification: { expiration, text, title, type, triggerOnClick, remove, } }) => {
    if (!title) {
        switch (type) {
            case NotificationType.ERROR:
                title = 'Error';
                break;
            case NotificationType.INFO:
                title = 'Info';
                break;
            case NotificationType.SUCCESS:
                title = 'Success';
                break;
        }
    }
    const onCloseClick = event => {
        event.stopPropagation();
        remove();
    };
    return React.createElement("div", { className: classnames('notification', {
            '--success': type === NotificationType.SUCCESS,
            '--danger': type === NotificationType.ERROR,
            '--info': type === NotificationType.INFO,
        }), onClick: triggerOnClick },
        React.createElement("div", { className: "__title" }, title),
        React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } }, text.split('\n').map((line, index) => React.createElement("span", { key: index },
            line,
            React.createElement("br", null)))),
        React.createElement("div", { className: "__loading-bar", style: {
                animationDuration: `${expiration}s`
            } }),
        React.createElement("div", { className: "__icon-wrapper", onClick: onCloseClick },
            React.createElement(XIcon, { className: "w-4 h-4" })));
};
