import { getParent, hasParent, types } from "mobx-state-tree";
export var NotificationType;
(function (NotificationType) {
    NotificationType["SUCCESS"] = "success";
    NotificationType["ERROR"] = "error";
    NotificationType["INFO"] = "info";
})(NotificationType || (NotificationType = {}));
export const NotificationModel = types.model({
    expiration: types.number,
    text: types.string,
    title: types.optional(types.string, ''),
    type: types.enumeration(Object.values(NotificationType)),
    uuid: types.string,
})
    .actions(self => {
    const remove = () => {
        if (hasParent(self, 2)) {
            getParent(self, 2).deleteNotification(self.uuid);
        }
    };
    let onClick;
    const addOnClick = (cb) => {
        onClick = cb;
    };
    const triggerOnClick = () => {
        if (onClick) {
            onClick(self);
        }
    };
    return {
        addOnClick,
        triggerOnClick,
        remove
    };
});
