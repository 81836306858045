import { store } from '@polo/common/state/models';
import React from 'react';
import { BrowserRouter, Switch, Route, useRouteMatch } from 'react-router-dom';
import './app.scss';
import 'tailwindcss/tailwind.css';
import whiteLogo from '@/assets/white-logo.png';
import blackLogo from '@/assets/black-logo.png';
import { observer } from 'mobx-react-lite';
import FailingSessionPage from './session/failing-session-page';
import { Notifications } from './notifications/notifications';
const Dashboard = React.lazy(() => import('@/components/dashboard/dashboard-page'));
const Session = React.lazy(() => import('@/components/session/session-page'));
export const App = observer(() => {
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: `
            flex-1
            w-full
            flex
            flex-col
            items-stretch
            justify-stretch
            min-w-0
            min-h-0
            relative
            text-black
            bg-gradient-to-br
            from-gray-50
            to-gray-100
            dark:from-nord-4 dark:to-nord-1
            dark:bg-gray-800
            dark:text-gray-300
            manager-app
            ${store.app.modal.visible ? '--blurred' : ''}
            ` },
            React.createElement("div", { className: "flex pt-10 pb-8 z-10" },
                React.createElement("div", { className: "w-full mx-auto lg:max-w-1500 lg:px-20 text-center lg:text-left" },
                    React.createElement("a", { href: "/_polo_/" },
                        React.createElement("picture", null,
                            React.createElement("source", { srcSet: whiteLogo, media: "(prefers-color-scheme: dark)" }),
                            React.createElement("img", { src: blackLogo, width: "200", className: "cursor-pointer inline-block" }))))),
            React.createElement(BrowserRouter, null,
                React.createElement(React.Suspense, { fallback: "" },
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: "/_polo_/", exact: true },
                            React.createElement(Dashboard, { app: store.app })),
                        React.createElement(Route, { path: "/_polo_/session/failing/:uuid" },
                            React.createElement(FailingSessionPage, { app: store.app })),
                        React.createElement(Route, { path: "/_polo_/session/:uuid" },
                            React.createElement(SessionRoute, null))))),
            React.createElement("div", { className: "absolute right-5 bottom-5 z-10 uppercase text-xs tracking-widest flex items-center" },
                React.createElement("a", { className: "", target: "_blank", href: "https://github.com/wufe/polo" },
                    "Powered by ",
                    React.createElement("b", null, "@wufe/polo")))),
        React.createElement(Notifications, null));
});
function SessionRoute() {
    const { path } = useRouteMatch();
    return React.createElement(Switch, null,
        React.createElement(Route, { path: `${path}/*` },
            React.createElement(Session, { app: store.app })));
}
