import { types } from "mobx-state-tree";
export var ApplicationNotificationType;
(function (ApplicationNotificationType) {
    ApplicationNotificationType["GIT_CLONE_ERROR"] = "git_clone_error";
    ApplicationNotificationType["GIT_FETCH_ERROR"] = "git_fetch_error";
})(ApplicationNotificationType || (ApplicationNotificationType = {}));
export var ApplicationNotificationLevel;
(function (ApplicationNotificationLevel) {
    ApplicationNotificationLevel["CRITICAL"] = "critical";
})(ApplicationNotificationLevel || (ApplicationNotificationLevel = {}));
export const ApplicationNotificationModel = types.model({
    uuid: types.string,
    type: types.enumeration(Object.values(ApplicationNotificationType)),
    permanent: types.boolean,
    level: types.enumeration(Object.values(ApplicationNotificationLevel)),
    description: types.string,
    createdAt: types.string,
});
