import { markFailedSessionAsAcknowledgedAPI, retrieveFailedSessionAPI, retrieveFailedSessionLogsAPI } from "@polo/common/api/applications";
import { flow, getParent, hasParent, types } from "mobx-state-tree";
import { SessionSubscriptionEventType } from ".";
import { SessionModel } from "./session-model";
export var FailureStatus;
(function (FailureStatus) {
    FailureStatus["ACK"] = "acknowledged";
    FailureStatus["UNACK"] = "unacknowledged";
})(FailureStatus || (FailureStatus = {}));
export const FailuresModel = types.model({
    acknowledged: types.map(SessionModel),
    unacknowledged: types.map(SessionModel),
})
    .actions(self => {
    const storeFailure = (session, status) => {
        let addMap = self.acknowledged;
        let removeMap = self.unacknowledged;
        if (status === FailureStatus.UNACK) {
            addMap = self.unacknowledged;
            removeMap = self.acknowledged;
        }
        if (!addMap.has(session.uuid)) {
            addMap.set(session.uuid, session);
            removeMap.delete(session.uuid);
            if (status === FailureStatus.UNACK && hasParent(self)) {
                getParent(self).publishSessionEvent(session, SessionSubscriptionEventType.FAIL);
            }
        }
    };
    const markFailedSessionAsAcknowledged = flow(function* markFailedSessionAsAcknowledged(uuid) {
        const request = yield markFailedSessionAsAcknowledgedAPI(uuid);
        return request;
    });
    const retrieveFailedSession = flow(function* retrieveFailedSession(uuid, markAsSeen = true) {
        const request = yield retrieveFailedSessionAPI(uuid);
        if (markAsSeen)
            markFailedSessionAsAcknowledged(uuid);
        return request;
    });
    const retrieveFailedSessionLogs = flow(function* retrieveFailedSessionLogs(uuid) {
        const request = yield retrieveFailedSessionLogsAPI(uuid);
        return request;
    });
    return { retrieveFailedSession, retrieveFailedSessionLogs, markFailedSessionAsAcknowledged, storeFailure };
})
    .views(self => {
    const sessionsToMap = (sessions, status, accumulator = {}) => {
        return sessions
            .reduce((accumulator, session) => {
            const applicationName = session.applicationName;
            if (!accumulator[applicationName]) {
                accumulator[applicationName] = {
                    [FailureStatus.ACK]: [],
                    [FailureStatus.UNACK]: []
                };
            }
            if (status === FailureStatus.ACK)
                accumulator[applicationName].acknowledged.push(session);
            else
                accumulator[applicationName].unacknowledged.push(session);
            return accumulator;
        }, accumulator);
    };
    return {
        get byApplicationName() {
            return sessionsToMap(Array.from(self.unacknowledged.values()), FailureStatus.UNACK, sessionsToMap(Array.from(self.acknowledged.values()), FailureStatus.ACK));
        }
    };
});
export const initialFailuresState = FailuresModel.create({});
