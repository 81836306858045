import React from 'react';
import dayjs from 'dayjs';
export const CommitMessage = (props) => {
    return React.createElement("blockquote", { className: "relative px-2 lg:px-4 py-3 italic text-gray-500 dark:text-gray-400 z-10 bg-nord6 shadow-md dark:bg-nord-5 leading-loose" },
        React.createElement("p", { className: `text-sm pb-1 flex flex-col min-h-0 ${!!props.maxHeight ? 'max-h-32' : ''} overflow-y-auto` }, props.commitMessage
            .split('\n')
            .map((line, key) => React.createElement("span", { key: key }, line))),
        React.createElement("cite", { className: "flex items-center" },
            React.createElement("span", { className: "mb-1 text-sm font-bold italic flex-1 mr-3" },
                "~ ",
                props.commitAuthorName),
            React.createElement("span", { className: "mb-1 text-xs font-light italic flex flex-nowrap" },
                React.createElement("span", { className: "sm:hidden" },
                    "(",
                    dayjs(props.commitDate).format('DD MMM HH:mm'),
                    ")"),
                React.createElement("span", { className: "hidden sm:block" },
                    "(",
                    props.commitAuthorEmail,
                    " - ",
                    dayjs(props.commitDate).format('DD MMM HH:mm'),
                    ")"))));
};
