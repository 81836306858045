import { APIRequestResult } from "../../api/common";
import { retrieveStatusDataAPI } from "../../api/applications";
import { retrieveSessionAPI } from "../../api/session";
import { types, flow } from "mobx-state-tree";
import { ApplicationModel, castAPIApplicationToApplicationModel } from "./application-model";
import { SessionModel, castAPISessionToSessionModel } from "./session-model";
import { initialModalState, ModalModel } from "./modal-model";
import { NotificationModel, NotificationType } from "./notification-model";
import { v1 } from 'uuid';
import { FailuresModel, FailureStatus, initialFailuresState } from "./failures-model";
export var SessionSubscriptionEventType;
(function (SessionSubscriptionEventType) {
    SessionSubscriptionEventType["FAIL"] = "fail";
})(SessionSubscriptionEventType || (SessionSubscriptionEventType = {}));
export const AppModel = types.model({
    session: types.maybeNull(SessionModel),
    sessions: types.map(SessionModel),
    failures: FailuresModel,
    applications: types.map(ApplicationModel),
    notifications: types.map(NotificationModel),
    modal: ModalModel,
})
    // #region Session events subscriptions
    .volatile(self => {
    return {
        subscriptions: []
    };
})
    .actions(self => {
    const subscribeToSessionEvents = (sessionUUID, event, cb) => {
        self.subscriptions.push({ sessionUUID, event, cb });
    };
    const publishSessionEvent = (s, e) => {
        for (const { sessionUUID, event, cb } of self.subscriptions) {
            if (sessionUUID === s.uuid && event === e)
                cb(s, event);
        }
    };
    return { subscribeToSessionEvents, publishSessionEvent };
})
    // #endregion
    .actions(self => {
    const retrieveSession = flow(function* retrieveSession(uuid) {
        const session = yield retrieveSessionAPI(uuid);
        if (session.result == APIRequestResult.SUCCEEDED) {
            self.session = castAPISessionToSessionModel(session.payload);
        }
        return session;
    });
    const retrieveStatusData = flow(function* retrieveStatusData() {
        const statusData = yield retrieveStatusDataAPI();
        if (statusData.result === APIRequestResult.SUCCEEDED) {
            const { applications, sessions, failures } = statusData.payload;
            const applicationsMap = applications.reduce((acc, application) => {
                acc[application.configuration.name] = castAPIApplicationToApplicationModel(application);
                return acc;
            }, {});
            self.applications.replace(applicationsMap);
            const sessionsMap = sessions.reduce((acc, session) => {
                session.beingReplacedBy = sessions.find(s => s.replacesSessions && s.replacesSessions.indexOf(session.uuid) > -1);
                acc[session.uuid] = castAPISessionToSessionModel(session);
                return acc;
            }, {});
            self.sessions.replace(sessionsMap);
            for (const session of failures.acknowledged) {
                self.failures.storeFailure(session, FailureStatus.ACK);
            }
            for (const session of failures.unacknowledged) {
                self.failures.storeFailure(session, FailureStatus.UNACK);
            }
        }
        return statusData;
    });
    return { retrieveSession, retrieveStatusData };
})
    .actions(self => {
    const deleteNotification = (uuid) => {
        self.notifications.delete(uuid);
    };
    return { deleteNotification };
})
    .actions(self => {
    const addNotification = ({ text, type = NotificationType.INFO, title = '', expiration = 10, onClick, }) => {
        const uuid = v1();
        const notification = NotificationModel.create({
            uuid,
            expiration,
            text,
            title,
            type
        });
        notification.addOnClick(onClick);
        self.notifications.set(uuid, notification);
        if (expiration > 0) {
            setTimeout(() => {
                self.deleteNotification(uuid);
            }, expiration * 1000);
        }
        return {
            notification,
            remove: () => self.deleteNotification(uuid),
        };
    };
    return { addNotification };
})
    .views(self => ({
    get sessionsByApplicationName() {
        return (Array.from(self.sessions.values()))
            .reduce((accumulator, session) => {
            const applicationName = session.applicationName;
            if (!accumulator[applicationName])
                accumulator[applicationName] = [];
            accumulator[applicationName].push(session);
            return accumulator;
        }, {});
    }
}));
export const initialAppState = AppModel.create({
    modal: initialModalState,
    failures: initialFailuresState,
});
